import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Slider from 'react-slick';
import CardMedia from '@mui/material/CardMedia';
import Card from '@mui/material/Card';
import useMediaQuery from '@mui/material/useMediaQuery';

// import Video1 from 'media/home.mp4';
// import Video2 from 'media/award.mp4';
// import Video4 from 'media/independanceday2024.mp4';
// import Video4 from 'media/runnerup.mp4';
// import Video3 from 'media/winners.mp4';

const Services = () => {
  const theme = useTheme();
  
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  // Move useState and useEffect inside the component
  const [config, setConfig] = useState({ base_video_url: '' });

  useEffect(() => {
    fetch('/config.json') // Fetching config.json from the static folder
      .then((response) => response.json())
      .then((data) => setConfig(data))
      .catch((error) => console.error('Error fetching config:', error));
  }, []);

  const Video2 = `${config.base_video_url}/award.mp4`;
  const Video3 = `${config.base_video_url}/Second_video.mp4`;  //onam
  const Video4 = `${config.base_video_url}/runnerup.mp4`;  //Runnerup Runnerup11

  const mock = [
    {
      media: Video2,
    },
    {
      media: Video3,
    },
    {
      media: Video4,
    },
    
   
  ];

  const sliderOpts = {
    dots: true,
    arrows: false,
    infinite: true,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 12500,
  };

  return (
    <Box
      data-aos={'fade-up'}
      maxWidth={{ xs: 420, sm: 620, md: 1 }}
      margin={'0 auto'}
    >
      <Slider {...sliderOpts}>
        {mock.map((item, i) => (
          <Box key={i} padding={{ xs: 1, md: 2, lg: 3 }}>
            <Box
              display={'block'}
              width={1}
              height={1}
              sx={{
                textDecoration: 'none',
                transition: 'all .2s ease-in-out',
                '&:hover': {
                  transform: `translateY(-${theme.spacing(1 / 2)})`,
                },
              }}
            >
              <Box
                component={Card}
                width={1}
                height={1}
                display={'flex'}
                flexDirection={'column'}
                sx={{ backgroundImage: 'none' }}
              >
                <CardMedia
                  component={'video'}
                  image={item.media}
                  autoPlay
                  muted  // Ensure the video is muted for autoplay
                  loop
                  sx={{
                    position: 'relative',
                    height: { xs: 440, sm: 640, md: 580 },
                    overflow: 'hidden',
                  }}
                >
                  <Box
                    component={'svg'}
                    preserveAspectRatio="none"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 1921 273"
                    sx={{
                      position: 'absolute',
                      width: '100%',
                      left: 0,
                      bottom: 0,
                      right: 0,
                      zIndex: 1,
                    }}
                  ></Box>
                </CardMedia>
              </Box>
            </Box>
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

export default Services;